import history from "../history";
import callNative from "./JioMiniApp/callNative";
//This fucntion will stop user activity using device back button

export default function handleStopDeviceBackButton() {
  // callNative({ type: "handleWebviewBack", value: true });
    history.push(window.location.pathname);
    window.onpopstate = function () {
    history.push(window.location.pathname);
    };
}