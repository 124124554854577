import { set, del, get } from 'idb-keyval';
import keys from '../common/browserStorageKeys'
import CryptoJS from "crypto-js";

var encryptionKey = "Wn";
var encryption_phrase = "JuTB9t76b84fk"
var encryptData = {
    // -------------------------------- STORING DATA IN LOCAL STORAGE -------------------------------
    setItemValue(itemName, itemValue) {
        localStorage.setItem(itemName, itemValue);
    },

    // -------------------------------- READING DATA FROM LOCAL STORAGE -------------------------------
    getItemValue(itemName) {
        if (localStorage.getItem(itemName)) {
            return localStorage.getItem(itemName);
        } else {
            return null;
        }
    },

    // -------------------------------- REMOVE DATA FROM LOCAL STORAGE -------------------------------
    removeItemValue(itemName) {
        localStorage.removeItem(itemName);
    },

    // -------------------------------- STORING DATA IN INDEXED DB -------------------------------
    setIndexedValue(itemName, itemValue) {
        set(itemName, itemValue);
    },

    // -------------------------------- FETCHING DATA FROM INDEXED DB -------------------------------
    async getIndexedValue(itemName) {
        if(itemName){
            return await get(itemName);
        }
    },

    // -------------------------------- REMOVE DATA FROM LOCAL STORAGE -------------------------------
    removeIndexedValue(itemName) {
        del(itemName);
    },

    // ------------------------- DELETE DATA FROM LOCAL STORAGE FOR APPLICATION----------------------
    removeLocalValueForApplication() {
        // del(keys.APPLIED_DATA);
        // del(keys.APPLICATION_FORM_FIELD_VALUES);
        del(keys.APPLICATION_FORM_DOCUMENT);
        del(keys.APPLICATION_FORM);
        del(keys.SAVE_KYC);
        del(keys.SELF_DECLERATION);
        del(keys.SURVEY_DATA_OF_APPLICATION);
        del(keys.DOCUMENT_LIST);
        del("autoVerifiedFields");
        del(keys.KYC_FORM);
        // del(keys.PROFILE_PIC);
        del(keys.PREVIOUS_DOCS_TO_DELETE);
        del(keys.APPLICATION_FILES);
        del(keys.DOCUMENT_LIST_FOR_API);
        this.removeItemValue(keys.KYC_SUBMITTED);
        this.removeItemValue(keys.MAPPING_IDS_FOR_DOCUMENT);
        this.removeItemValue(keys.TERM_AND_CONDITIONS);
        this.removeItemValue(keys.APPLICATION_FORM_ID);
        this.removeItemValue(keys.BENEFITS_LIST);
    },
    // ------------------------------------- SET DATA TO COOKIES -----------------------------------
  setCookies(itemName, itemValue) {
    let encryptedItemValue = CryptoJS.AES.encrypt(itemValue, encryptionKey);
    let expiry_in_days = 7;
    var d = new Date();
    d.setTime(d.getTime() + expiry_in_days * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
      itemName + "=" + encryptedItemValue + ";" + expires + ";path=/;";
  },

  // -------------------------------- SET DATA TO COOKIES -------------------------------
  setFlagCookies(itemName, itemValue) {
    let encryptedItemValue = CryptoJS.AES.encrypt(itemValue, encryptionKey);
    var d = new Date();
    d.setTime(d.getTime() + 4 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString() + ";";
    document.cookie =
      itemName + "=" + encryptedItemValue + ";" + expires + ";path=/;";
  },

  // -------------------------------- READ COOKIES DATA -------------------------------
  getCookies(itemName) {
    var result;
    let value = (result = new RegExp(
      "(?:^|; )" + encodeURIComponent(itemName) + "=([^;]*)"
    ).exec(document.cookie))
      ? result[1]
      : null;
    if (value) {
      let bytes = CryptoJS.AES.decrypt(value.toString(), encryptionKey);
      let decryptedItemValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedItemValue;
    } else {
      return null;
    }
  },

  // -------------------------------- DELETE COOKIES FROM SYSTEM -------------------------------
  removeCookies(itemName) {
    document.cookie = itemName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    // delete_cookie('roundcube_sessauth');
    // document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
  },

  // -------------------------------- ENCRYPT DATA -------------------------------

  perform_encryption(itemValue) {
    var encryptedAES = CryptoJS.SHA512(itemValue, encryption_phrase);
    return encryptedAES.toString();
  },

  // -------------------------------- DECRYPT DATA -------------------------------
  perform_decryption(itemValue) {
    let bytes = CryptoJS.SHA512.decrypt(itemValue, "");
    let decryptedItemValue = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedItemValue
  },

};

export default encryptData;
