import axios from "axios";
import {HandleApiError} from "../../utility/handleApiError"
import { responseTime } from "../../utility/responseTime";

export const getSettings = (loader = true) => {
  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "",
    },
  });

  return (dispatch) => {
    const startTime = performance.now();
    if(loader){
      dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    }
    instance
      .post("get_settings")
      .then((response) => {
         responseTime(performance.now(),startTime)
        if (response.status === 200) {
          dispatch({ type: "GET_SETTINGS", payload: response.data.data });
      dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });

        }
      })
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
        console.log(err);  
        HandleApiError(err)    
      });
  };
};
