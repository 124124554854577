/**
 * Track event to google analytics.
 * @param {string} name - Event name | required.
 * @param {string} action - Event action | required.
 * @param {string} label - Event label | optional.
 * @param {object} additionalInfo - Additional information to pass | optional.
 */
const gaTrackEvent = (name, action, label = "", additionalInfo = {}) => {
  if (typeof window !== undefined) {
    return window.gtag("event", name, {
      app_name: "Jio citizen",
      action,
      label,
      ...additionalInfo,
    });
  }
  console.warn("Google analytics is not working.");
  return null;
};

export default gaTrackEvent;
