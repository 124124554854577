import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import RoutesMobile from "./components/Routes";
import history from "./history";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSettings } from "./store/actions/getSettingsAction";
import browserStorage from "./utility/browserStorage";
import browserStorageKeys from "./common/browserStorageKeys";
import $ from "jquery";
import Loader from "./components/elements/loader";
import NoNetworkPage from "./components/pages/noNetworkScreen";
import url_utility from "./utility/url"
import handleBrowserName from "./utility/handleBrowserName";
import { deepLinkVisit } from "./store/actions/deepLinkVisitAction";
function App() {
  const [hitAppOpenCount, setHitAppOpenCount] = useState(false);
  const [isUrlVisit, setIsUrlVisit] = useState(true);
  const [loader,setLoader]= useState(true);
  const [noInternetScreen, setNotInternetScreen] = useState(false)
  const settings = useSelector((state) => state.settings.data);
  const showLoader = useSelector((state) => state.showLoader.data.showLoader);
  const closeModalUrl = ["/survey", "categorySurvey", "/specificSurvey"]
  const dispatch = useDispatch();
  //This use Effect will hit getSettings Api
  useEffect(() => {
    setLoader(true);
    dispatch(getSettings());
    window.addEventListener('online', ()=>handleInternetConnection())
    window.addEventListener("offline", ()=>handleNoInternet())
    if (browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)) {
      var langSet = JSON.parse(
        browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
      );
      langSet = langSet.code;
      if (
        browserStorage.getItemValue(browserStorageKeys.LANG_TYPE) == langSet
      ) {
        $("body").addClass(langSet);
      }
    }
    let params_link = url_utility.params.get_param();
    if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("source")){
      browserStorage.setItemValue(browserStorageKeys.DEEP_LINK_SOURCE, JSON.stringify(params_link));
      let deepLinkUrl = window.location.href;
      browserStorage.setItemValue(browserStorageKeys.DEEP_LINK_URL, deepLinkUrl);
      handleDeepLinkVisitApiCall();
    }
  }, []);

  const handleDeepLinkVisitApiCall = () => {
    const deepLinkVisitObj = JSON.parse(browserStorage.getItemValue(browserStorageKeys.DEEP_LINK_SOURCE));
    const deepLinkData = {
       productId: 12980,
       hostName: window.location.href,
       source: deepLinkVisitObj?.source,
       browser: handleBrowserName() 
    }
    dispatch(deepLinkVisit(deepLinkData));
  }
  const handleInternetConnection = () =>{
    if(closeModalUrl.includes(window.location.pathname)){
      setNotInternetScreen(false)
    }
    else{
      history.go(0)
    }
    }
    const handleNoInternet = () => {
      dispatch({type: "HANDLE_LOADER", payload:{showLoader: false}})
      setNotInternetScreen(true)
    }

  //This useEffect hook is for saving token, checking platform and also saving JWT from getSettings
  useEffect(() => {
    if (settings) {
      if(!browserStorage.getItemValue(browserStorageKeys.GET_SETTINGS_VERSION)){
        browserStorage.setItemValue(browserStorageKeys.SETTINGS_DATA, JSON.stringify(settings));
        browserStorage.setItemValue(browserStorageKeys.GET_SETTINGS_VERSION, settings.current_version);
        browserStorage.setItemValue(browserStorageKeys.CURRENT_PRODUCT, "scheme");
      }else{
        if(browserStorage.getItemValue(browserStorageKeys.GET_SETTINGS_VERSION) !== settings.current_version){
          localStorage.clear();
          browserStorage.setItemValue(browserStorageKeys.SETTINGS_DATA, JSON.stringify(settings));
          browserStorage.setItemValue(browserStorageKeys.GET_SETTINGS_VERSION, settings.current_version);
          browserStorage.setItemValue(browserStorageKeys.CURRENT_PRODUCT, "scheme");
        }
      }
    }
  }, [settings]);
  return (
    <div className="App">
      {showLoader && loader === true? <Loader /> : null}
      {noInternetScreen === true ? <NoNetworkPage/>:null}
      <BrowserRouter history={history}>
        <Suspense fallback={<Loader />}>
          <RoutesMobile />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
