import browserStorageKeys from "../common/browserStorageKeys";
import browseStorage from "./browserStorage";

export function responseTime(endTime,startTime){
let apiExpiryTime = browseStorage.getItemValue(browserStorageKeys.SETTINGS_DATA) ? (JSON.parse(browseStorage.getItemValue(browserStorageKeys.SETTINGS_DATA)).api_expiry_time * 1000) : 10000;

if(endTime - startTime >=apiExpiryTime){
  window.location.assign("/somethingWentWrong");
  }
}
