import isAndroid from './isAndroid'
import callAndroid from './callAndroid'
import callIOS from './callIos'
import isIOS from './isIos'

const callNative = (msgObj = {}, mockResp = null) => {
    if (isAndroid()) {
      return callAndroid(msgObj)
    } else if (isIOS()) {
      return callIOS(msgObj)
    }
}

export default callNative;
