import axios from "axios";
import history from "../../history"

export const deepLinkVisit = (formData) => {

    const instance = axios.create({
      baseURL: window.HOST,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return (dispatch) => {
      dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
      instance
        .post("/capture-deeplink-visit/", formData)
        .then((response) => {
          if (response.status === 200) {
            if(response.data.code === 401){
              if(window.outerWidth <=600){
                history.push("/main")
              } else {
                history.push("/homeUser")
              }
              dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
            } else {
              dispatch({ type: "DEEP_LINK_VISIT", payload: response });
              dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
            }
          }
        })
        .catch((err) => {
          dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
          console.log(err);
        });
    };
  };