const initState = {
    data: "",
    downloadLink:""
  };

  const printApplicationReducer = (state = initState, action) => {
    switch (action.type) {
      case "PRINT_APPLICATION":
        return {
          ...state,
          data: action.payload.data,
          downloadLink: action.payload.downloadLink
        };

        case "PRINT_APPLICATION_RESET":
          return {
            ...state,
            data: "",
            downloadLink:""
          };

      default:
        return state;
    }
  };

  export default printApplicationReducer;
