import storage from '../../utility/browserStorage'
import keys from '../../common/browserStorageKeys'

const initState = {
  data: "",
};

const saveJwtReducer = (state = initState, action) => {
  switch (action.type) {
    case "SAVE_JWT":
        // console.log("action", action);
      storage.setItemValue(keys.AUTHORIZATION_TOKEN, action.payload.token)
      storage.setItemValue(keys.USERNAME, action.payload.uname)
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default saveJwtReducer;
