const initState = {
  data: "",
};

const dataLoaderReducer = (state = initState, action) => {
  switch (action.type) {
    case "HANDLE_DATA_LOADER":
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default dataLoaderReducer;
