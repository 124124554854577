import browserStorage from '../../utility/browserStorage'
import browserStorageKeys from '../../common/browserStorageKeys';

const initState = {
  data: "",
};

const getRecommendedProductsReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_RECOMMENDED_PRODUCTS":
      browserStorage.setIndexedValue(browserStorageKeys.RECOMMENDED_SCHEMES_LIST, action.payload.objects)
      browserStorage.setIndexedValue(browserStorageKeys.TAGGING_DATA_LIST, action.payload.taggingData)
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_RECOMMENDED_PRODUCTS_LIST":
      browserStorage.setIndexedValue(browserStorageKeys.RECOMMENDED_SCHEMES_LIST, [])
      browserStorage.setIndexedValue(browserStorageKeys.TAGGING_DATA_LIST, [])
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default getRecommendedProductsReducer;
