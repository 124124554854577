const initState = {
  data: "",
};

const fetchPaymentGatewayReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_PAYMENT_GATEWAY_LIST":
      return {
        ...state,
        data: action.payload,
    };
    case "RESET_PAYMENT_GATEWAY_LIST":
      return {
        ...state,
        date: "",
      };
    default:
      return state;
  }
};

export default fetchPaymentGatewayReducer;
