const initState = {
  invoiceData: "",
  invoiceDownloadLink:""
  };

  const printReceiptReducer = (state = initState, action) => {
    switch (action.type) {
      case "PRINT_RECEIPT":
        return {
          ...state,
          invoiceData:action.payload.invoiceData,
          invoiceDownloadLink: action.payload.invoiceDownloadLink
        };

        case "PRINT_RECEIPT_RESET":
          return {
            ...state,
            invoiceData:"",
            invoiceDownloadLink:""
          };

      default:
        return state;
    }
  };

  export default printReceiptReducer;
