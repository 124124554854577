import storage from "./browserStorage";
import keys from "../common/browserStorageKeys";

const evaluateRule = (rule, groups, field) => {
  // console.log(rule);
  let isRulePassed = false
  groups.map((group) => {
    let profileList = group.userProfiles.filter(profile => profile.hasOwnProperty('fieldValue') && profile.fieldValue.trim() !== "")
    profileList.map((question) =>{
      let shortNameString = "{{" + question.fieldShortName + "}}";
      var newNameString = shortNameString.replace(/[(]/g,"\\(").replace(/[)]/g,"\\)")
      var regExp = new RegExp(newNameString, "g");

      if(question.hasOwnProperty('selectedValue')){
        rule = rule.replace(regExp, question.selectedValue);
      } else if(question.hasOwnProperty('fieldValue') && question.fieldValue.trim() !== ""){
        if(question.hasOwnProperty('untranslated_fieldValue')){
          rule = rule.replace(regExp, question.untranslated_fieldValue);
        } else {
          if(question.fieldValue.includes(rule)){
          }
          rule = rule.replace(regExp, question.fieldValue);
        }
      }
    })
  });
  var splittedRule = rule.split("|/|");
  for (let i = 0; i < splittedRule.length; i++) {
    // if(splittedRule[i].includes("((")){
    //   while(splittedRule[i].includes("((")){
    //     splittedRule[i] = clearInitialBrackets(splittedRule[i])
    //   }
    // }
    // if(splittedRule[i].includes(")))")){
    //   while(splittedRule[i].includes(")))")){
    //     splittedRule[i] = clearEndingBrackets(splittedRule[i])
    //   }
    // }
    // console.log("splittedRule[i]", splittedRule[i]);
    try {
      if(splittedRule[i].includes("'{{")) {
        splittedRule[i] = false
      } else {
        splittedRule[i] = eval(splittedRule[i])
      }
    } catch(err){
      // console.log("======================");
      // console.log("field", field);
      // console.log("splittedRule[i]", splittedRule[i]);
      // console.log("rule", rule);
      // console.log("======================");
    }
    isRulePassed = isRulePassed || splittedRule[i];
  }
  let obj = {
    rule: rule,
    isRulePassed: isRulePassed
  }
  // return isRulePassed;
  return obj;
}

const clearInitialBrackets = (string) => {
  return string.replace("(", "");
}

const clearEndingBrackets = (string) => {
  let a = reverseString(string);
  a = a.replace(")", "");
  return reverseString(a);
}

const reverseString = (str) => {
  const revArray = [];
  const length = str.length - 1;
  for (let i = length; i >= 0; i--) {
    revArray.push(str[i]);
  }
  return revArray.join("");
}

export default evaluateRule;
