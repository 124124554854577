// import keys from '../../common/browserStorageKeys'
// import storage from "../../utility/encryptData";

const initState = {
  data: "",
};

const getUserFamilyProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_USER_FAMILY_PROFILE":
      // if(action.payload && action.payload.familyMembersList.length > 0){
      //   storage.setItemValue(keys.FAMILY_MEMBERS_LIST, JSON.stringify(action.payload.familyMembersList))
      // }
      // let currentActiveProfile = JSON.parse(storage.getItemValue(keys.ACTIVE_PROFILE))
      // if(currentActiveProfile && action.payload){
      //   let activeProfileObj = action.payload.familyMembersList.find( item => item.fullName.toLowerCase().trim() === currentActiveProfile.name.toLowerCase().trim() && item.relationship === currentActiveProfile.relation)
      //   if(activeProfileObj){
      //     storage.setItemValue(keys.AUTHORIZATION_TOKEN, activeProfileObj.authToken)
      //     if(currentActiveProfile && !currentActiveProfile.hasOwnProperty('userIdTo')){
      //       currentActiveProfile['userIdTo'] = activeProfileObj.userId
      //       storage.setItemValue(keys.ACTIVE_PROFILE, JSON.stringify(currentActiveProfile))
      //     }
      //   }
      // }

      return {
        ...state,
        data: action.payload,
      };

    case "RESET_FAMILY_DATA":
      return {
        ...state,
        data: undefined,
      };

    default:
      return state;
  }
};

export default getUserFamilyProfileReducer;
