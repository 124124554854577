export function HandleApiError(error){
if(error.response.status === 404 || error.response.status === 403){
    window.location.assign("/resourceNotAvailable");
  }
  if(error.response.status === 401){
    window.location.assign("/accessDenied");
  }
  if(error.response.status=== 408 || error.response.status === 504){
    window.location.assign("/serverTimeOut");
  }
  if(error.response.status.toString().charAt(0) === "5" && !error.response.status === 504){
    window.location.assign("/somethingWentWrong");
    }
  else{
     return "error"
    }
}
