import React, { useEffect, useState,useRef,createContext } from "react";
import LoaderMobileView from "./mobileView";
import browserStorage from '../../../utility/browserStorage'
import browserStorageKeys from "../../../common/browserStorageKeys";
import getStaticText from '../../../staticText'


export default function Loader(props) {
  const [staticText, setStaticText] = useState(
    browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
      ? getStaticText(
          JSON.parse(
            browserStorage.getItemValue(browserStorageKeys.LANGUAGE_OBJ)
          ).code
        )
      : getStaticText("en")
  );
    return (
        <>
                {
          window.outerWidth >768 ?  
          null : <LoaderMobileView loader = {Loader} staticText={staticText}/>
        }
        </>
      );
    }