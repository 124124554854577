import React from "react";
import browserStorageKeys from "../../../common/browserStorageKeys";
import browserStorage from "../../../utility/browserStorage";

export default function LoaderMobileView(props) {
  const staticText = props.staticText;
  const currentProduct = browserStorage.getItemValue(browserStorageKeys.CURRENT_PRODUCT)
  let header;
  if(browserStorage.getItemValue(browserStorageKeys.SURVEY_TYPE)){
  const surveyType = JSON.parse(browserStorage.getItemValue(browserStorageKeys.SURVEY_TYPE))
   header =surveyType?.header
  }
  return (
    <>
      {((currentProduct === "scheme" || currentProduct === "SCHEME")||(header==="Schemes" && currentProduct === "SEARCH")) && (window.location.pathname === "/schemes" || window.location.pathname === "/home/benefit" || window.location.pathname === "/home/category") ? (
        <div className="modal-container" style={{ backgroundColor: "white" }}>
        <div className="center-container" >
          <img
            className="padding-b-2-1"
            style={{ height: "20vh" }}
            src={process.env.PUBLIC_URL + "/imgs/logo/scheme-loader.gif"}
            alt="loading"
          />
          <p className="size-xl text-coralGray lh-1-9 ls-0">
          {staticText['TM_Con15']}
          ...
          </p>
        </div>
        </div>
      ) : (
        <div className="modal-container" style={{ backgroundColor: "white" }}>
          <div className="modal-loader d-flex direction-column align-items-center">
            <img
              className="padding-b-2"
              style={{ width: "150px" }}
              src={process.env.PUBLIC_URL + "../imgs/logo/anim-loading-1.gif"}
              alt="loading"
            />
            <p className="text-coralGray size-xl ls-0 ">
              {staticText["TM_Con11"]}
              ...
            </p>
          </div>
        </div>
      )}
    </>
  );
}
