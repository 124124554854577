import React, { useEffect, useState,useRef,createContext } from "react";
import NoNetworkPageMobileView from "./mobileView";

export default function NoNetworkPage() {

    return (
        <>
                {
          window.outerWidth >768 ?  
          null : <NoNetworkPageMobileView/>
        }
        </>
      );
    }