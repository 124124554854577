const initState = {
    data : "",
}

const locationByGeoCordinatesReducer = (state = initState, action) =>{
    switch (action.type){
        case "GET_GEO_CORDINATES":
            return {
                ...state,
                data: action.payload,
            }
        case "RESET_GEO_CORDINATES":
            return {
                ...state,
                data: ""
            }
        default :
        return state
    }
}

export default locationByGeoCordinatesReducer;