import browserStorage from '../../utility/browserStorage'
import browserStorageKeys from '../../common/browserStorageKeys';
import keys from '../../common/browserStorageKeys'

const initState = {
  data: "",
};

const getProductReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_PRODUCT":
      browserStorage.setIndexedValue(browserStorageKeys.PRODUCT_LIST, action.payload.objects)
      browserStorage.setIndexedValue(browserStorageKeys.TAGGING_LIST, action.payload.taggingData)
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_PRODUCT_LIST":
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default getProductReducer;
