var language = {
  "TM_Txf6": "Please select your preferred language",
  "TM_But15": "Proceed",
  "TM_Pag19": "Select Language",
  "TM_But1": "Let's get started",
  "TM_Pag20": "Select Location",
  "TM_Txf9": "Providing your location will help us show you the benefits you are eligible for in your area",
  "TM_Txf3": "Search State",
  "TM_Txf4": "Search District",
  "TM_But16": "Next",
  "TM_Alt1": "Labharthi needs some basic profile information for finding best suited Government Welfare Schemes for you.",
  "TM_Alt07": "Check Eligibilty for Services",
  "TM_Alt3": "Find job openings near you",
  "TM_Alt2": "Check services you want to apply",
  "TM_Lin1": "By accessing the App, you agree to Labharthi's: ",
  "TM_Lin21": "By accessing the App, you agree to Labharthi's: ",
  "TM_Lin3": "Privacy Policy",
  "TM_Alt5": "Create profile to help us find the welfare benefits you might be eligible for",
  "TM_But2": "Create your Profile",
  "TX_Sub1": "Scheme Categories",
  "TX_Sub2": "Flagship Schemes",
  "TX_Sub3": "Newly Added Schemes",
  "TX_Sub4": "Popular Schemes",
  "TM_Pag1": "Scheme Categories",
  "TM_Alt6": "Schemes",
  "TM_Alt7": "Services",
  "TM_Alt8": "Jobs",
  "TM_Alt9": "Search",
  "TM_Alt10": "Profile",
  "TX_Sub7": "Service Categories",
  "TX_Sub8": "Top Rated Services",
  "TX_Sub9": "Newly Added Services",
  "TX_Sub10": "Popular Services",
  "TX_Sub13": "My Services",
  "TM_Pag6": "My Services",
  "TM_Pag18": "Service Categories",
  "TM_Alt16": "Create profile to help us find most suited jobs for you",
  "TM_Alt17": "Job Details",
  "TM_Alt18": "Job Description",
  "TM_Alt19": "English",
  "TM_Alt20": "Hindi",
  "TM_Alt21": "Tamil",
  "TM_Alt22": "Kannada",
  "TM_Alt23": "Punjabi",
  "TM_Alt24": "Malayalam",
  "TM_Alt25": "Bengali",
  "TM_Alt26": "Gujarati",
  "TM_Alt27": "Telugu",
  "TM_Alt28": "Marathi",
  "TM_But3": "View All",
  "TM_But5": "Check Eligibilty",
  "TM_But10": "Explore More Jobs",
  "TX_Sub15": "Top Rated Jobs",
  "TX_Sub16": "Newly Added Jobs",
  "TX_Sub17": "Popular Jobs",
  "TX_Sub18": "Recommended Jobs",
  "TX_Sub20": "My Jobs",
  "TM_Pag9": "Job Categories",
  "TM_Pag21": "Schemes",
  "TM_Pag22": "Services",
  "TM_Pag23": "Jobs",
  "TM_Alt30": "Search for Services",
  "TM_Lab6": "Recently Searched",
  "TM_Err4": "No Results Found",
  "TM_Err12": "This service is not available in your location.",
  "TM_Pag12": "Job Details",
  "TM_Tag1": "Just Launched",
  "TM_Alt12": "Eligibility",
  "TM_alt11": "Benefits",
  "TX_Sub25": "FAQs",
  "TM_Sup1": "Follow us",
  "TM_Hep17": "In case you have any query let us know and we will get back to you within 48 business hours.",
  "TM_Lab4": "Email",
  "TM_Lin2": "Terms and Conditions",
  "TM_But4": "Change User",
  "TM_Pag15": "Change User",
  "TM_Hep36": "You can select or add your desired profile to view the eligibility or to process your saved data further.",
  "TM_But18": "Add User",
  "TM_Mod19": "Are you sure do you want to Logout?",
  "TM_Mod5": "Are you sure you want to permanently delete {{Name}} account?",
  "TM_Rec7": "When you delete your Labharthi account, you won’t be able to retrieve any data or information stored in your profile and other profiles associated with your account. All your recommended and saved schemes will also be deleted.",
  "TM_Err2": "You cannot delete the {{Name}} profile as it has an ongoing application(s).",
  "TM_But11": "Delete",
  "TM_But12": "Cancel",
  "TM_Rec11":"Are you sure you want to delete the selected notifications?",
  "TM_Mod7":"Delete Notifications",
  "TM_But30": "View All Jobs",
  "TM_But31": "View All Schemes",
  "TM_Con11": "Loading",
  "TM_The1": "Saved",
  "TM_The2": "Draft",
  "TM_The3": "Applied",
  "TM_The4": "Enrolled",
  "TM_The5":"Processed",
  "TM_But25":"Select All",
  "TM_Hep54":"Today",
  "TM_Hep55":"Yesterday",
  "TM_Hep7": "No Saved Schemes",
  "TM_Hep21": "No Draft Schemes",
  "TM_Hep22": "No Applied Schemes",
  "TM_Hep23": "No Enrolled Schemes",
  "TM_But7": "Explore More Schemes",
  "TM_Mod1": "Delete Scheme",
  "TM_Hep6":"No Saved Services",
  "TM_Hep13":"No Draft Services",
  "TM_Hep14":"No Applied Services",
  "TM_Hep15":"No Notifications",
  "TM_Hep44":"No Processed Services",
  "TM_Mod2": "Delete Service",
  "TM_Rec1": "Are you sure you want to delete this scheme from your ‘My Schemes’?",
  "TM_Rec2": "Are you sure you want to delete this service from your ‘My Services’?",
  "TM_Pag2": "My Schemes",
  "TM_Hep10": "You can change your preferred language and location from the Profile",
  "TM_But21": "Got It!",
  "TM_Alt29": "Language & Location",
  "TM_Hep38": "To help us identify suitable schemes in {{category}} category, please answer some additional questions related to {{category}}",
  "TM_Hep11": "Based on your profile, we did not find you eligible for any scheme. We are continuously adding more schemes, please check later.",
  "TM_Hep39": "To help us identify suitable jobs in {{category}} category, please answer some additional questions related to {{category}}",
  "TM_Rec6": "There are no Jobs configured in your state at the moment, we are continuously updating, please check with us again",
  "TM_But27": "OK",
  "TM_Mod25":"Switch Language",
  "TM_Rec33":"This service is available only in English language. Click on proceed to change the language of the App",
  "TX_Sub5": "Recommended Schemes",
  "TM_Hep42": "Based on your profile, we did not find you eligible for any job. We are continuously adding more jobs, please check later.",
  "TM_Hep40": "Apply to",
  "TM_Hep41": "Hours",
  "TM_Hep2": "Category",
  "TM_Alt31": "In Service",
  "TM_Alt32": "In Scheme",
  "TM_Alt33": "In Job",
  "TM_Pag14": "Create Profile",
  "TM_Hep9": "Creating a profile allows you to save all opportunities in which you are interested, and to come back later at any time to process your saved data further.",
  "TM_Txf1": "Full Name",
  "TM_Txf15": "Add Name",
  "TM_Txf2": "Relation",
  "TM_But29": "Save",
  "TM_Err1": "Profile with same name and relation already exists!",
  "TM_Err6": "Only characters are allowed",
  "TM_Pag16": "Change Language",
  "TM_But20":"Edit",
  "TM_Pag33": "Update Location",
  "TM_But22": "Update",
  "TM_But14": "Add Profile",
  "TM_Pag17": "Change Location",
  "TM_Pag13": "Job Benefits",
  "TM_Pag29" : "Profile Creation",
  "TM_Con9":"Sorry! We couldn't find any matching schemes for you",
  "TM_Con10":"Sorry! We couldn't find any matching jobs for you",
  "TM_But26": "Home",
  "TM_Rec35": "Resource not available",
  "TM_Rec36":"Server time out!",
  "TM_Rec37":"Access Denied!",
  "TM_Mod28":"Something went wrong.",
  "TM_Rec10": "We are continuously improving, please try again later.",
  "TM_But24":"Retry",
  "TM_Pag26": "Confirm Location",
  "TM_But23": "Confirm",
  "TM_Hep16": "Choose your correct location in order to enhance your experience while using the app and display the accurate schemes for you.",
  "TX_Sub21":"Notifications",
  "TX_Sub22":"Manage Profile",
  "TX_Sub23":"Location",
  "TX_Sub24":"Language",
  "TX_Sub26":"About Us",
  "TX_Sub27":"Delete Account",
  "TX_Sub28":"Personal Details",
  "TX_Sub29":"Economic Details ",
  "TX_Sub30":"Housing Details",
  "TX_Sub31":"Education Profile",
  "TX_Sub32":"Occupation Profile",
  "TX_Sub33":"Health Profile",
  "TM_Txf13":"Select State",
  "TM_Txf14":"Select District",
  "TM_Hep43":"Swipe right for previous question",
  "TM_Hep28":"Answer the questions to find relevant schemes as per your profile",
  "TM_Hep30":"Answer the questions to find relevant jobs as per your profile",
  "TM_Hep32": "You may be eligible for",
  "TM_Hep35":"Answer a few questions",
  "TM_Rel1":"Daughter",
  "TM_Rel2":"Father",
  "TM_Rel3":"Mother",
  "TM_Rel4":"Son",
  "TM_Rel5":"Brother",
  "TM_Rel6":"Sister",
  "TM_Rel7":"Spouse",
  "TM_Rel8":"Someone Else",
  "TM_Rel9": "Self",
  "TM_Mod4": "Attention",
  "TM_Rec12": "If you change the data the same will also be updated in your profile data. Do you want to continue?",
  "TM_Hep12": "Review and confirm your previously saved data.",
  "TM_But28": "Continue",
  "TM_Pag24": "Review Your Details",
  "TM_Pag25": "Update Your Details",
  "TM_Hep33":"You're doing good! Keep going...",
  "TM_Con15":"Please wait while we fetch the details",
  "TM_But9" : "Explore More Services",
  "TM_Pag5":"Scheme Details",
  "TM_Pag8":"Service Details",
  "TM_Hep3": "Service Level",
  "TM_But6": "Copy Link",
  "TM_But19": "Download",
  "TM_Con1": "Link Copied",
  "TM_Con5": "Downloaded",
  "TM_Alt14": "How To Apply",
  "TM_Alt13": "Documents",
  "TM_Alt15": "Information",
  "TM_Con3": "This scheme has been saved in your “My Schemes”",
  "TM_Con2": "This service has been saved in your “My Services”",
  "TM_Alt34":"Procedure to Apply",
  "TM_Alt35":"Application Link",
  "TM_Alt36":"Download Form",
  "TM_Alt38":"Selection Procedure",
  "TM_Alt41":"Scheme Benefits",
  "TM_Alt56":"Posts",
  "TM_Alt57":"Salary",
  "TM_Alt58":"Job Details",
  "TM_Alt59":"Last Date To Apply",
  "TM_Txf12": "Reason",
  "TM_Hep51": "Eligible Values",
  "TM_Hep52": "Value provided by you",
  "TM_Con7": "Sorry, You are not eligible for this scheme",
  "TM_Con8": "Sorry, You are not eligible for this job",
  "TM_Err5" : "No options left",
  "TM_Con13" : "Your data has been saved successfully.",
  "TM_Hep8" : "No Saved Jobs",
  "TM_Pag10": "My Jobs",
  "TM_Mod3" : "Delete Job",
  "TM_Rec3" : "Are you sure you want to delete this job from your ‘My Jobs’?",
  "TM_Hep24": "No Draft Jobs",
  "TM_Hep25": "No Applied Jobs",
  "TM_Hep26": "No Enrolled Jobs",
  "TM_Hep27": 'All expired jobs will be removed from your "My Jobs" after a period of 30 days from the date of expiry.',
  "TM_Tag3" : "Expired",
  "TM_Tag4" : "Apply By",
  "TM_Con4":  'This job has been saved in your "My Jobs"',
  "TM_Rec5": "All your data has been deleted",
  "TM_Rec4": "To delete your account you must first delete all profiles associated with your account",
  "TM_But58": "Proceed To Apply",
  "TM_But17": "Submit",
  "TM_Pag41": "Application Details",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "Are you sure you want to remove this document?",
  "TM_Mod18": "Gallery",
  "TM_Mod17": "Camera",
  "91412465ea9169dfd901dd5e7c96dd99": "Upload ",
  "af6ebe0c0e89201cb2294f32955f7b15": "Only 20 documents are allowed at a time .",
  "d5de543c0e83073f05a846d62cc83c81": "Document already uploaded for the same field.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "Enter valid",
  "3f68e67dc6c397aaa9d1c24c356f754f": "Verified",
  "1e410e1b9903e7a99b93857f536b7e1d": "Not Verified",
  "f8d855b26faffa0f8779fb44632d6bc1": "Invalid Captcha Code",
  "d53b4d564b9054db9b4b4a50e713197f": "Please enter the Captcha here",
  "TM_Con18": "Your Application has been successfully saved in your ‘Draft Scheme’",
  "TM_Con24": "Your Application has been successfully saved in your ‘Draft Job’",
  "TM_Con23": `Your application has been successfully saved in your "Draft Services"`,
  "6cf24d7a07132fe88b610e2b3d3def28": "Self Declaration",
  // field validation messages
  "9303c9bd4f8178680dc382adbfcd62af": "This field is required.",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "Please enter only alphabets, numbers, comma, -, _, /",
  "562d3d08919ae105c3ebc42d9607d266": "Please enter only alphabets or numbers",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "Only alphabets are allowed.",
  "4906d981bb592b32f6982fb4187c7b60": "Enter a valid IFSC Code.",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "Only 11 characters are allowed.",
  "7c58cde3b827bc7d57cbf77865046169": "Please enter only numbers",
  "544b0264d55a1e4cf476b279240d9be4": "Only ten digits are allowed. Please don't prefix 0 or +91",
  "73f0d980e48b8141e0776e02d96a872f": "Only twelve digits are allowed.",
  "0ce98bd973419ff33921a2275e7903b9": "Please enter a valid PAN Number.",
  "e55e425dbaf6b6449940eac8ea749092": "Please enter only alphabets.",
  "64ff9e3809686d4ea3253a631cae4761": "Please enter a value correct to 2 decimal places.",
  "7ed0ef23d527c2876015161e422f49ce": "Please enter your name in Kannada language.",
  "230486b5dd7afed96556ba9bd4175423": "Pin Code is incorrect",
  "9f44bd93c8988c682d5ef5369fd11f47": "Please enter valid email",
  "ac598199ea0dd028ade4677a435caf4b": "Enter a valid Aadhaar Number",
  "10803b83a68db8f7e7a33e3b41e184d0": "Date of Birth",
  "d0de720ccdffea8490dfd2893c50ebc5": "It should be greater than {{displayName}}",
  "ced6e171e13ebd5c37415c822706b9d4": "It should be lesser than {{displayName}}",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "It should be equal to {{displayName}}",
  "9483135517d156229d0439dff74b1ca0": "It should be greater than or equal to {{displayName}}",
  "01e9cd748e852b1ae389b980bff195b0": "It should be lesser than or equal to {{displayName}}",
  "TM_Pag37": "Review Details",
  "TM_Rec18": "Click on “Edit” to change your filled details",
  "TM_Alt51": "Attachment(s):",
  "TM_Alt42": "Thank You",
  "TM_But9":"Explore More Services",
  "TM_Con17":"Your application has been downloaded.",
  "TM_But37":"View Application",
  "TM_Tag8":"Status",
  "TM_Sub48": "Help and Support",
  "TM_Pag35": "Application Status",
  "TM_Alt66": "Send us an email on <b>jioeasygov@easygov.co.in</b> and our team will revert back to you shortly",
  "TM_Alt65": "Need assisstance with any query?",
  "TM_Pag54": "Service Benefits",
  "TM_Alt37": "Government Fee",
  "TM_But105" : "Explore More {{Services}}",
  "TM_Alt39": "Submission Address",
  "TM_But100" : "Back",
  "TM_But95": "Pay Now",
  "TM_Alt88": "Please pay the following amount to submit your application.",
  "TM_Con32": "Your application has been successfully submitted",
  "TM_But110": "Application ",
  "TM_Txf16": "Search here",
  "TM_Hep69": "Click on ‘Get Scheme Support’ and take a short survey, which will help us to provide you with the right support.",
  "TM_But106": "Get Scheme Support",
  "TM_But107": "What is 'Get Scheme Support'?",
  "TM_Pag32": "Scheme Awareness Survey",
  "TM_Con14": "Thank you for sharing the details.",
  "TM_Tag5": "Openings",
  "TM_Alt107": "Days",  
  "TM_Rec50": "Are you sure you want to withdraw your application?",
  "TM_But23": "Confirm",
  "TM_But12": "Cancel",
  "TM_Con6": "An OTP has been sent on your registered mobile number (xxxxxx{{phoneNumber}})",
  "TM_But17": "Submit",
  "TM_But61": "Resend OTP",
  "TM_Con20": "Your application has been successfully withdrawn ",
  "TM_But38": "Complete Actions",
  "TM_But62": "Give Feedback",
  "TM_Lab11": "Withdraw Application",
  "TM_Err3" : "Invalid OTP",
  "TM_Err13": "OTP Expired",
  "TM_Err14": "You have exceeded the maximum limit to generate the OTP, please try again after 3 hours.",
  "TM_But40": "Actions",
  "TM_Tag9": "actions needed",
  "TM_Tag10": "No Action needed",
  "TM_Tag8" : "Status",
  "TM_But76" : "View",
  "TM_Alt87" : "Not available online",
  "TM_Alt107": "Days",
  "TM_Pag34": "Payment Details",
  "TM_Hea1": "Total Amount",
  "TM_Hea2": "Summary",
  "TM_Sub56": "Total",
  "TM_But42": "Pay",
  "TM_Lab18": "View Receipt",
  "TM_Err34": "Your application could not be submitted due to payment failure.",
  "TM_Rec55": "It is taking time to confirm the status of your payment.We are continuously checking with your bank. Please avoid making any additional payments for the next 48 hours.",
  "TM_But13": "Go Back",
  "TM_Alt124":"Please wait",
  "TM_Rec58": "Hold on.We are verifying your payment status",
  "TM_Rec59": "Your last payment for this service is in pending status. We'll notify you when it's complete. In the meantime, feel free to explore our other services . Thank you for your patience. ",
  "TM_But133": "Click on Link",
  "UK_appName": "Labharthi",
  "UK_PoweredBy": "Powered by",
  "UK_OTPMsg": "Please Enter OTP received on Phone",
  "UK_PhoneNumber": "Phone Number",
  "UK_OTP": "OTP",
  "UK_Password": "Password",
  "UK_ForgotPassword": "Don't have an account or Forgot Password?",
  "UK_ClickHere": "Click here",
  "UK_SignUp": "Sign Up",
  "UK_Login": "Login",
  "UK_LogOut": "Logout",
  "UK_NewPassword": "New Password",
  "UK_ConfirmPassword": "Confirm Password",
  "UK_ErrorMsg1": "Either Phone number or Password is incorrect",
  "UK_ErrorMsg2": "Please enter correct phone number",
  "UK_ErrorMsg3": "Please enter correct password",
  "UK_ErrorMsg4": "Please Enter Password",
  "UK_ErrorMsg5": "New password and Confirm password should be same",
  "UK_ErrorMsg6": "User Not Found, Please Signup",
  "UK_LastUpdated": "Last updated on",
  "UK_ErrorMsg7": "Incorrect OTP",
  "UK_ErrorMsg8": "Your password must be at least 8 characters long, contain at least one number, one special character and have a mixture of uppercase and lowercase letters.",
  "UK_ErrorMsg9": "OTP limit exceeded",
  "6e0fe1553e84e34da97a6c4c9040b31b": "Labharthi",
  "e0cf58fa3413df99ae83109ebb9a41a3": "Resend OTP",
  "a943d4ac1dd2e0048bcbf85225bf2811": "You have exceeded the maximum limit to generate the OTP, Please try again after 3 Hours",
}


export default language
