import storage from '../../utility/browserStorage'
import keys from '../../common/browserStorageKeys'
import _ from 'lodash-es'

const initState = {
  data: "",
};

const getSavedApplicationReducer = (state = initState, action) => {
  switch (action && action.type) {
    case "GET_SAVED_APPLICATION":
      let applicationFormData = action.payload
      var newApplicationData = []
      if (applicationFormData) {
        storage.removeLocalValueForApplication();
        let applicationData = {}
        let surveyDetails = []
        storage.setIndexedValue(keys.DOCUMENT_LIST, applicationFormData.documentList)
        storage.setIndexedValue(keys.SELF_DECLERATION, applicationFormData.declaration)
        storage.setItemValue(keys.TERM_AND_CONDITIONS, applicationFormData.tnc)
        storage.setItemValue(keys.APPLICATION_FORM_ID, applicationFormData.productApplicationFormId)
        storage.setItemValue(keys.USER_SERVICE_ID, applicationFormData.applicationId)
        storage.setItemValue(keys.IS_PRINTABLE_APPLICATION_FORM_PRESENT, applicationFormData.isProductApplicationFormPresent)
        applicationFormData.data.surveyDetails.map(survey => {
          surveyDetails[survey.srbm] = survey.displayValue
        })
        storage.setIndexedValue(keys.SURVEY_DATA_OF_APPLICATION, surveyDetails)
        storage.setIndexedValue(keys.SURVEY_DATA_FOR_EXTERNAL_SOURCE, applicationFormData.data.surveyDetails)
        // getappliedData().then(result => {
          if (storage.getItemValue(keys.FROM_COMPLETE_ACTION)) {
            let fieldsWithWritePermission = action.appliedData.fieldsWithAction.filter(item => item.fieldPermission == "WRITE")
            let fieldsWithReadPermission = action.appliedData.fieldsWithAction.filter(item => item.fieldPermission == "READ")
            let fieldsWithReadPermissionFinal = []

            var applicationDataAll = [...applicationFormData.data.files, ...applicationFormData.data.userData]
            fieldsWithReadPermission.map(field => {
              let commonReadField = applicationDataAll.find(item => item.fieldId === field.fieldId)
              if (commonReadField)
                fieldsWithReadPermissionFinal.push(commonReadField)
            })
            var applicationFields = [...fieldsWithWritePermission, ...fieldsWithReadPermissionFinal]

            newApplicationData = applicationFormData.productApplicationForm.map(item => {
              var obj = {}
              let commonFields = item.formFields.filter(field => applicationFields.find(id => id.fieldId === field.fieldId))
              if (commonFields.length > 0) {
                var fields = commonFields.map(function (el) {
                  var o = Object.assign({}, el);
                  o.fieldPermission = applicationFields.find(id => id.fieldId === el.fieldId).fieldPermission;
                  o.val = applicationFields.find(id => id.fieldId === el.fieldId).val;
                  return o;
                })
                obj = {
                  "name": item.name,
                  "index": item.index,
                  "shortName": item.shortName,
                  "formFields": fields
                }
              }
              return obj
            })
            newApplicationData = newApplicationData.filter(item => Object.keys(item).length > 0)
            applicationFormData.productApplicationForm = newApplicationData
          }
          applicationFormData.productApplicationForm.map(item => {
            item.formFields.map(field => {
              if (field.fieldType == "file") {
                if (!field.document) {
                  let doc = applicationFormData.documentList.docTypes.find(
                    (document) => field.fieldId === document.fieldId
                  );
                  if (doc) {
                    let selectedDoc = doc.docs[0].name
                    let uploadedDoc = applicationFormData.data.files.filter((obj) =>
                      obj.fieldId == doc.fieldId
                    )

                    if (uploadedDoc) {
                      uploadedDoc.map(doct => {
                        selectedDoc = doct.documentTypeName
                        let obj = { id: doct.id }
                        if (!doc.uploadedFiles)
                          doc['uploadedFiles'] = []

                        if (doc.uploadedFiles) {
                          let dataa = doc.uploadedFiles.find((value) => doct.id == value.id)
                          if (!dataa) {
                            doc.uploadedFiles.push(obj)
                          }
                        }
                        else {
                          doc.uploadedFiles.push(obj)
                        }
                      })

                      if (selectedDoc) {
                        let docDetail = doc.docs.find(dc => dc.name == selectedDoc || (dc.hasOwnProperty('untranslated_name') && dc.untranslated_name === selectedDoc))
                        if (docDetail) {
                          doc["selectedDocId"] = docDetail.id
                        }
                      }
                      doc["selectedDoc"] = selectedDoc
                    }
                    field['document'] = doc;
                  }
                }
              }
              if (field.fieldType == "geography") {
                if (applicationFormData.data.hasOwnProperty('userData') && applicationFormData.data.userData) {
                  let authVal = applicationFormData.data.userData.find(value => (value.fieldId == field.fieldId))

                  if (authVal && authVal.val && authVal.val != 0) {
                    field['isAlreadyAnswered'] = true
                    field['getStoredAuthorityMaster'] = authVal.val
                  }
                }
              }
              else if (field.hasOwnProperty('useAuthority') && field.useAuthority == 1) {
                if (applicationFormData.data.hasOwnProperty('userData') && applicationFormData.data.userData) {
                  let authVal = applicationFormData.data.userData.find(value => (value.fieldId == field.fieldId))
                  if (!authVal)
                    authVal = applicationFormData.data.files.find(value => (value.fieldId == field.fieldId))
                  if (authVal && authVal.getStoredAuthorityMaster && authVal.getStoredAuthorityMaster != 0) {
                    field['isAlreadyAnswered'] = true
                    field['getStoredAuthorityMaster'] = authVal.getStoredAuthorityMaster
                  }
                }
              }
            })
          })
          storage.setItemValue(keys.BENEFITS_LIST, JSON.stringify(applicationFormData.benefitsList))
          applicationData['formId'] = parseInt(applicationFormData.productApplicationFormId)
          storage.setIndexedValue(keys.APPLICATION_FILES, applicationFormData.data.files)
          applicationData['serviceId'] = storage.getItemValue(keys.SGM_FOR_STATUS)
          applicationData['userServiceId'] = parseInt(storage.getItemValue(keys.USER_SERVICE_ID))

          let ans = {}
          applicationFormData.data.hasOwnProperty('userData') && applicationFormData.data.userData.map((value) => {
            ans[value.fieldShortName] = value.val
          })
          applicationData["fieldParameters"] = ans

          storage.setIndexedValue(keys.APPLICATION_FORM_FIELD_VALUES, applicationData)
          storage.setIndexedValue(keys.APPLICATION_FORM, applicationFormData.productApplicationForm)

          ans = {}
          applicationFormData.data.hasOwnProperty('kycDetails') && applicationFormData.data.kycDetails.map((value) => {
            ans[value.kycShortName] = value.value
          })
          let saveKycData = {}
          saveKycData['fieldParameters'] = ans
          saveKycData['geoId'] = JSON.parse(storage.getItemValue(keys.GEOGRAPHY_ID))
          saveKycData['applicationFormId'] = parseInt(storage.getItemValue(keys.APPLICATION_FORM_ID))
          saveKycData['applicationId'] = parseInt(storage.getItemValue(keys.USER_SERVICE_ID))
          saveKycData['passedRuleId'] = applicationFormData.passedRuleId.toString()
          storage.setIndexedValue(keys.SAVE_KYC, saveKycData)

          applicationFormData.kycForm = [..._.sortBy(_.flatten(applicationFormData.kycForm, function (x) { return x.index; }), ['index'])]
          applicationFormData.kycForm = [...applicationFormData.kycForm.filter(group => group.shortName.trim().toLowerCase() === "default"),
          ...applicationFormData.kycForm.filter(group => group.shortName.trim().toLowerCase() !== "default")]

          applicationFormData.kycForm.map(item => {
            item.formFields.map(field => {

              if (field.fieldType == "geography") {
                if (applicationFormData.data.hasOwnProperty('kycDetails') && applicationFormData.data.kycDetails) {
                  let authVal = applicationFormData.data.kycDetails.find(value => (value.kycShortName == field.shortName))

                  if (authVal && authVal.value && authVal.value != 0) {
                    field['isAlreadyAnswered'] = true
                    field['getStoredAuthorityMaster'] = authVal.value
                  }
                }
              }

              else if (field.hasOwnProperty('useAuthority') && field.useAuthority == 1) {
                if (applicationFormData.data.hasOwnProperty('kycDetails') && applicationFormData.data.kycDetails) {
                  let authVal = applicationFormData.data.kycDetails.find(value => (value.kycShortName == field.shortName))
                  if (authVal && authVal.getStoredAuthorityMaster && authVal.getStoredAuthorityMaster != 0) {
                    field['isAlreadyAnswered'] = true
                    field['getStoredAuthorityMaster'] = authVal.getStoredAuthorityMaster
                  }
                }
              }
            })
          })

          storage.setIndexedValue(keys.KYC_FORM, applicationFormData.kycForm)

        // })
      }
          return {
              ...state,
              data: applicationFormData,
          };


    case "RESET_SAVED_APPLICATION":
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default getSavedApplicationReducer;
