const initState = {
    data: "",
  };
  
  const generatePaymentLinkReducer = (state = initState, action) => {
    switch (action.type) {
      case "GENERATE_PAYMENT_LINK":
        return {
          ...state,
          data: action.payload,
      };
      case "RESET_GENERATE_PAYMENT_LINK":
        return {
          ...state,
          date: "",
        };
      default:
        return state;
    }
  };
  
  export default generatePaymentLinkReducer;
  