const initState = {
    data: "",
  };
  
  const getPaymentStatusReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_PAYMENT_STATUS":
        return {
          ...state,
          data: action.payload,
      };
      case "RESET_GET_PAYMENT_STATUS":
        return {
          ...state,
          date: "",
        };
      default:
        return state;
    }
  };
  
  export default getPaymentStatusReducer;
  