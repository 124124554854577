import React, { useEffect, useState, useRef, createContext } from "react";
import Header from "../../elements/header";
import {imageData} from "./imageData";
export default function NoNetworkPageMobileView() {
    return (
        <>
            <div className="nonetwork">
                <img
                    className=" margin-b-2-5"
                    src={`data:image/png;base64,${imageData}`}
                    alt="Submit Button"
                    style={{ width: "100%" }}
                />
            </div>

        </>
    );
}