import browserStorageKeys from "../common/browserStorageKeys";
import browserStorage from "./browserStorage";

const handleDeviceBackButton = () => {
  if (browserStorage.getItemValue(browserStorageKeys.CURRENT_PRODUCT)) {
    const currentProductType = browserStorage
      .getItemValue(browserStorageKeys.CURRENT_PRODUCT)
      .toLowerCase();
    if (currentProductType === "scheme") {
      return "/home";
    } else if (currentProductType === "service") {
      return "/services";
    } else if (currentProductType === "jobs") {
      return "/homejobs";
    } else if (currentProductType === "profile") {
      return "/profile";
    } else if (currentProductType === "search") {
      return "/search";
    } else {
      return "/home";
    }
  }
  return "/home";
};

export default handleDeviceBackButton;
