import en from "./en";
import hi from "./hi";

const language = (code) => {
  if (code === "en") return en;
  if (code === "hi") return hi;
  else return en;
};

export default language;
