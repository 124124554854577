import storage from "./browserStorage";
import keys from "../common/browserStorageKeys";
import evaluateRule from './evaluateRule';

const handlePossibleFieldValue = (field, profileList) => {
  let isPossibleRulePassed = false
  let ruleList = field.possibleValues.split("|/|")
  let optionsList = null
  for(let i=0; i<ruleList.length; i++){
    let rule = ruleList[i]
    let evaluateRuleObj = evaluateRule(rule.split("?")[0] + ')', profileList, field)
    isPossibleRulePassed = evaluateRuleObj.isRulePassed
    let ruleString = evaluateRuleObj.rule.substring(0, evaluateRuleObj.rule.length-1) + "?" + rule.split("?")[1]
    try{
      if(ruleList.length > 1){
        if(isPossibleRulePassed){
          optionsList = eval(ruleString)
          break;
        }
      } else {
        optionsList = eval(ruleString)
      }
    }
    catch(err){
      console.log("==============================");
      console.log("rule", rule);
      console.log("==============================");
    }
  }
  return optionsList;
}

export default handlePossibleFieldValue;
